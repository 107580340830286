<script>
  import { mdiFileDocumentEditOutline, mdiTrashCanOutline } from "@mdi/js";
  import { computed, defineComponent } from "@vue/composition-api";

  import { formatCurrency } from "@/helpers/filter";
  import { useUpdateEstimateItemsList } from "@/views/apps/construction-record/useConstructionRecord";

  export default defineComponent({
    props: {
      selectedItems: {
        type: Array,
        required: true
      },
      contingency: {
        type: String,
        required: true
      },
      datatableKey: {
        type: Number,
        required: true
      }
    },
    setup(props) {
      const { tableHeaders } = useUpdateEstimateItemsList();

      const visibleSelectedItems = computed(() => {
        return props.selectedItems.filter((i) => i.item_qty > 0);
      });
      //const selectedItemsList = store.state['estimate-items'].selectedItemsList;
      const calcSubtotal = () => {
        return props.selectedItems.reduce(
          (previous, current) =>
            previous + current.item_cost * current.item_qty,
          0
        );
      };

      return {
        tableHeaders,
        formatCurrency,
        visibleSelectedItems,
        calcSubtotal,
        icons: {
          mdiTrashCanOutline,
          mdiFileDocumentEditOutline
        }
      };
    }
  });
</script>
<template>
  <div>
    <v-card v-if="visibleSelectedItems.length > 0" flat>
      <v-card-title>
        Statement Items ({{ visibleSelectedItems.length }})
      </v-card-title>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-fade-transition>
          <!-- Selected Estimate Items -->
          <v-data-table
            :headers="tableHeaders"
            :items="visibleSelectedItems"
            item-key="item_num"
            :key="datatableKey"
          >
            <template v-slot:item.item_cost="{ item }">
              <span v-if="item.item_qty > 0" class="font-weight-regular">
                {{ formatCurrency(item.item_cost) }}
              </span>
              <span v-else class="font-weight-semibold">$0.00</span>
            </template>
            <template v-slot:item.total="{ item }">
              <span v-if="item.item_qty > 0" class="font-weight-semibold">
                {{ formatCurrency(item.item_cost * item.item_qty) }}
              </span>
              <span v-else class="font-weight-semibold">$0.00</span>
            </template>
            <!-- actions -->
            <!-- NOTE: The v-slot modifier is NOT an error -->
            <template v-slot:item.actions="{ item }">
              <v-icon
                color="error"
                dense
                @click="$emit('remove-selected-item', { item })"
              >
                {{ icons.mdiTrashCanOutline }}
              </v-icon>
              <v-icon
                class="ml-1"
                color="primary"
                dense
                @click="$emit('set-selected-item', { item })"
              >
                {{ icons.mdiFileDocumentEditOutline }}
              </v-icon>
            </template>
          </v-data-table>
        </v-fade-transition>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text v-if="selectedItems.length > 0">
        <div class="d-flex justify-end py-3">
          <div>
            <table class="w-full">
              <tr>
                <td class="pe-16">Subtotal:</td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                  {{ formatCurrency(calcSubtotal()) }}
                </th>
              </tr>
              <tr>
                <td class="pe-16">Contingency:</td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                  {{ contingency }}%
                </th>
              </tr>
            </table>
            <v-divider class="mt-4 mb-3"></v-divider>
            <table class="w-full">
              <tr>
                <td class="pe-16">Total:</td>
                <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">
                  {{
                    formatCurrency(
                      calcSubtotal() * (contingency / 100) + calcSubtotal()
                    )
                  }}
                </th>
              </tr>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
