var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var reset = ref.reset;
return [_c('div',{staticClass:"add-products-form pt-9 pb-10 px-8"},[_c('div',{staticClass:"single-product-form"},[_c('div',{staticClass:"add-products-header mb-2 d-none d-md-flex"},[_c('div',{staticClass:"px-5 flex-grow-1 font-weight-semibold"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('span',[_vm._v("Item")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('span',[_vm._v("Unit")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('span',[_vm._v("UOM")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('span',[_vm._v("QTY")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('span',[_vm._v("Total")])])],1)],1),_c('div',{staticClass:"header-spacer"})]),_c('v-card',{staticClass:"d-flex",attrs:{"flat":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addToSelectedItemsList.apply(null, arguments)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"pa-5 flex-grow-1"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Estimate Item","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.estimateItemsHaveLoaded)?_c('v-combobox',{attrs:{"id":"estimate-items","items":_vm.latestEstimateItemsQuery.response.data,"search-input":_vm.search,"loading":_vm.isLoading,"return-object":"","dense":"","hide-selected":"","clearable":"","no-filter":"","item-value":"fulcrum_id","menu-props":{ offsetY: true },"outlined":"","placeholder":_vm.selectedItem.status == 'NIB' ? 'NIBXXXX' : '',"error-messages":errors,"prepend-inner-icon":_vm.icons.mdiMagnify,"tabindex":"0"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"input":function (obj) { return _vm.updateItemForm(obj, 'default'); },"click:clear":_vm.clearSelectedEstimateItem},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.item_num)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',{staticClass:"font-weight-regular my-5"},[_c('v-list-item',{domProps:{"textContent":_vm._s(((item.item_num) + "-" + (item.item_desc)))}})],1)]}},{key:"append-item",fn:function(){return [(_vm.selectedItem.status == 'NIB')?_c('v-list-item-content',[_c('v-list-item',[_c('validation-provider',{attrs:{"name":"NIB Item","vid":"nib","rules":{ regexNIB: /(NIB\d{4,})/ },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"nib-item","filled":"","flat":"","dense":"","clearable":"","hide-details":"auto","placeholder":'NIBXXXX',"error-messages":errors,"tabindex":"1"},on:{"click:clear":_vm.clearSelectedEstimateItem},model:{value:(_vm.selectedItem.item_num),callback:function ($$v) {_vm.$set(_vm.selectedItem, "item_num", $$v)},expression:"selectedItem.item_num"}})]}}],null,true)})],1)],1):_vm._e(),(_vm.selectedItem.status !== 'NIB')?_c('v-list-item-content',[_c('v-list-item',{staticClass:"d-flex justify-center font-weight-semibold primary--text",on:{"click":function($event){return _vm.updateItemForm(null, 'custom')}}},[_vm._v(" CREATE NEW ITEM ")])],1):_vm._e()]},proxy:true}],null,true),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"4"}},[_c('validation-provider',{attrs:{"name":"Unit Cost","rules":"required|double","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"unit-cost","outlined":"","dense":"","disabled":_vm.selectedItem.negotiable !== 'Yes',"hide-details":"auto","append-icon":_vm.selectedItem.negotiable == 'Yes'
                        ? _vm.icons.mdiHandshakeOutline
                        : null,"prepend-inner-icon":_vm.icons.mdiCurrencyUsd,"error-messages":errors,"tabindex":"2"},model:{value:(_vm.selectedItem.item_cost),callback:function ($$v) {_vm.$set(_vm.selectedItem, "item_cost", $$v)},expression:"selectedItem.item_cost"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('validation-provider',{attrs:{"name":"Unit of Measure","rules":{ required: true, regexUppercase: /^[A-Z]+$/ },"mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"unit-of-measure","outlined":"","dense":"","disabled":_vm.selectedItem.negotiable !== 'Yes' ||
                      _vm.selectedItem.status !== 'NIB',"hide-details":"auto","error-messages":errors,"tabindex":"3"},model:{value:(_vm.selectedItem.item_unit),callback:function ($$v) {_vm.$set(_vm.selectedItem, "item_unit", $$v)},expression:"selectedItem.item_unit"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"2"}},[_c('validation-provider',{attrs:{"name":"Quantity","rules":"required|double:2|min:0.01","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"qty","outlined":"","dense":"","min":"0.01","type":"number","hide-details":"auto","placeholder":"QTY","error-messages":errors,"tabindex":"4"},on:{"input":function($event){return _vm.$emit('update', $event)}},model:{value:(_vm.selectedItem.item_qty),callback:function ($$v) {_vm.$set(_vm.selectedItem, "item_qty", _vm._n($$v))},expression:"selectedItem.item_qty"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"2","sm":"4"}},[_c('p',{staticClass:"font-weight-semibold my-2"},[_c('span',{staticClass:"d-inline d-md-none"},[_vm._v("Price: ")]),(_vm.selectedItem && _vm.selectedItem.item_qty > 0)?_c('span',[_vm._v(" "+_vm._s(_vm.formatCurrency( _vm.selectedItem.item_cost * _vm.selectedItem.item_qty ))+" ")]):_c('span',[_vm._v("$0.00")])])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required","mode":"eager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-textarea',{attrs:{"id":"Description","outlined":"","disabled":_vm.selectedItem.negotiable !== 'Yes' ||
                      _vm.selectedItem.status !== 'NIB',"rows":"4","hide-details":"auto","placeholder":"Description","error-messages":errors,"tabindex":"5"},model:{value:(_vm.selectedItem.item_desc),callback:function ($$v) {_vm.$set(_vm.selectedItem, "item_desc", $$v)},expression:"selectedItem.item_desc"}})]}}],null,true)})],1)],1)],1)])])],1),_c('div',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[(_vm.isEditSession)?_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.clearSelectedEstimateItem}},[_vm._v(" Update Item ")]):_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.addToSelectedItemsList}},[_vm._v(" Add Item ")])],1)],1)],1)]),_c('construction-record-selected-items',{attrs:{"selectedItems":_vm.selectedItemsList,"contingency":_vm.contingency,"datatableKey":_vm.datatableKey},on:{"set-selected-item":_vm.setSelectedItem,"remove-selected-item":_vm.removeSelectedItem}}),(_vm.loading)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }