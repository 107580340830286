import { computed, ref } from "@vue/composition-api";
import { useFind } from "feathers-vuex";
import Vue from "vue";

import { useRouter } from "@/helpers";
import { store } from "@/store";
const { EstimateItem } = Vue.$FeathersVuex.api;

export function useEstimateItemsByConstructionRecordId() {
  const { route } = useRouter();

  const search = ref(null);
  const estimateItemsParams = computed(() => {
    return {
      query: {}
    };
  });
  const estimateItemsFetchParams = computed(() => {
    let q = {};
    let $and = [];
    if (store.state["estimate-items"].filterText !== null) {
      q = {
        query: {
          //$limit: store.state["estimate-items"].pageLimit,
          $limit: 1000,
          //$skip: store.state["estimate-items"].pageSkip,
          project_id: route.value.params.project,
          const_id: route.value.params.record,
          $sort: { item_desc: 1 }
          // item_num: {
          //   $iLike: store.state["estimate-items"].filterText + "%"
          // }
          // $and: [
          //   {
          //     $or: [
          //       {
          //         item_desc: {
          //           $iLike: "%" + store.state["estimate-items"].filterText + "%"
          //         }
          //       },
          //       {
          //         item_num: {
          //           $iLike: store.state["estimate-items"].filterText + "%"
          //         }
          //       }
          //     ]
          //   }
          // ]
        }
      };

      // if (typeof store.state["estimate-items"].filterText === "string") {
      //   q.query["item_desc"] = {
      //     $iLike: "%" + store.state["estimate-items"].filterText + "%"
      //   }
      // }

      // if (typeof store.state["estimate-items"].filterText === "number") {
      //   q.query["item_num"] = {
      //     $iLike: store.state["estimate-items"].filterText + "%"
      //   }
      // }

      $and = [
        {
          $or: [
            {
              item_desc: {
                $iLike: "%" + store.state["estimate-items"].filterText + "%"
              }
            },
            {
              item_num: {
                $iLike: store.state["estimate-items"].filterText + "%"
              }
            }
          ]
        }
      ];
      Object.assign(q.query, { $and });
    } else {
      q = {
        query: {
          //$limit: store.state["estimate-items"].pageLimit,
          $limit: 1000,
          project_id: route.value.params.project,
          const_id: route.value.params.record,
          item_num: { $ne: null },
          $sort: { item_desc: 1 }
        }
      };
    }
    console.log("estimateItemsParams", q);
    return q;
  });

  const {
    items: estimateItems,
    isPending: estimateItemsArePending,
    haveLoaded: estimateItemsHaveLoaded,
    latestQuery: latestEstimateItemsQuery
  } = useFind({
    model: EstimateItem,
    params: estimateItemsParams,
    fetchParams: estimateItemsFetchParams,
    qid: "estimateItemsList"
  });

  const selectedEstimateItems = EstimateItem.findInStore({
    query: {
      project_id: route.value.params.project,
      const_id: route.value.params.record,
      item_qty: {
        $ne: null
      }
    }
  }).data;
  store.dispatch(
    "estimate-items/updateSelectedItemsList",
    selectedEstimateItems
  );

  console.log("estimateItems", estimateItems);
  return {
    search,
    estimateItems,
    estimateItemsHaveLoaded,
    estimateItemsArePending,
    latestEstimateItemsQuery,
    selectedEstimateItems
  };
}
