<script>
  import {
    mdiCurrencyUsd,
    mdiFileDocumentEditOutline,
    mdiHandshakeOutline,
    mdiMagnify,
    mdiOpenInNew,
    mdiTrashCanOutline
  } from "@mdi/js";
  import { computed, defineComponent, ref, watch } from "@vue/composition-api";
  import { watchDebounced } from "@vueuse/core";
  import Vue from "vue";

  import { useEstimateItemsByConstructionRecordId } from "@/composables/useEstimateItems";
  import { formatCurrency } from "@/helpers/filter";
  import { store } from "@/store";
  const { ContractorItem, EstimateItem } = Vue.$FeathersVuex.api;
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  import ConstructionRecordSelectedItems from "@/views/apps/construction-record/construction-record-edit/ConstructionRecordSelectedItems.vue";

  export default defineComponent({
    components: {
      ValidationProvider,
      ValidationObserver,
      ConstructionRecordSelectedItems
      //LoadRecordEstimateItems
    },
    props: {
      projectId: {
        type: String,
        required: true
      },
      contractorListRecord: {
        type: String,
        required: true
      },
      constId: {
        type: String,
        required: true
      },
      contingency: {
        type: String,
        required: true
      },
      datatableKey: {
        type: Number,
        required: true
      }
    },
    setup(props) {
      const isLoading = ref(false);

      const {
        search,
        estimateItems,
        estimateItemsHaveLoaded,
        latestEstimateItemsQuery,
        selectedEstimateItems
      } = useEstimateItemsByConstructionRecordId();

      let selectedItem = ref({});

      const shouldDialogBeOpen = computed(() => {
        if (estimateItems.length > 0) {
          return false;
        } else {
          return true;
        }
      });

      let selectedItemsList =
        store.getters["estimate-items/getSelectedItemsList"];
      const fulcrumRemovalItemsList =
        store.getters["estimate-items/getFulcrumRemovalItemsList"];
      const loading = computed(() => {
        return store.state["estimate-items"].loading;
      });

      const isEditSession = ref(false);
      const form = ref(null);

      const updateItemForm = (val, itemType) => {
        if (itemType === "default") {
          if (val !== null) {
            val.status = "In-Bid";
            selectedItem.value = val;
          } else {
            selectedItem.value = {};
          }
        } else if (itemType === "custom") {
          let estimateItem = new EstimateItem({});
          selectedItem.value = estimateItem.clone();
          (selectedItem.value.status = "NIB"),
            (selectedItem.value.project_id = props.projectId),
            (selectedItem.value.const_id = props.constId),
            (selectedItem.value.negotiable = "Yes");
        }
      };

      const addToSelectedItemsList = () => {
        if (selectedItem.value.fulcrum_id) {
          const removeIndex = fulcrumRemovalItemsList.indexOf(
            selectedItem.value.fulcrum_id
          );
          if (removeIndex !== -1) {
            fulcrumRemovalItemsList.splice(removeIndex, 1);
          }
        }
        // push to array if item isn't in there
        const index = selectedItemsList.findIndex(
          (o) => o.item_num === selectedItem.value.item_num
        );

        if (index == -1) {
          // item isn't in the selectedItems array
          selectedItemsList.push(selectedItem.value);

          form.value.reset();
        } else {
          // item is in the selectedItems array

          form.value.reset();
        }

        selectedItem.value = {};
      };

      const setSelectedItem = (val) => {
        selectedItem.value = val.item;
        isEditSession.value = true;
      };

      const clearSelectedEstimateItem = () => {
        selectedItem.value = {};

        store.dispatch("estimate-items/filterText", null);
        isEditSession.value = false;
        form.value.reset();
      };

      const removeSelectedItem = async (val) => {
        /**
         *
         * We have different types of item removals we need to handle.
         * 1. Default Item: reset `item_qty = 0`
         * 2. Negotiable Item: get original `item_cost` from `estimate-items` store and set `item_qty = 0`
         * 3. NIB Item Already Saved in System: remove item from array and add its fulcrum_id to `fulcrumRemovalItemsList` array for removal on server side
         * 4. New NIB Item (Not Yet Saved in System): remove item from array
         *
         */
        console.log("Inside removeSelectedItem");
        console.log(val.item);
        if (selectedItemsList.length == 0) {
          selectedItemsList = store.state["estimate-items"].selectedItemsList;
        }
        console.log("selectedItemsList: ", selectedItemsList);
        if (val.item.status == "In-Bid" && val.item.negotiable == "No") {
          val.item.status = "Short List";
          val.item.item_qty = null;
        } else if (
          val.item.status == "In-Bid" &&
          val.item.negotiable == "Yes"
        ) {
          val.item.status = "Short List";
          val.item.item_qty = null;
          // get original negotiable value
          let originalCost = await ContractorItem.find({
            query: {
              $limit: 1,
              $select: ["item_cost"],
              contractor_list_record: props.contractorListRecord,
              short_list_item_record: val.item.item_record
            }
          });

          val.item.item_cost = originalCost.data[0].item_cost;
        } else if (
          val.item.status == "NIB" &&
          val.item.fulcrum_id !== undefined &&
          val.item.fulcrum_id !== null
        ) {
          fulcrumRemovalItemsList.push(val.item.fulcrum_id);
          const removeIndex = selectedItemsList.findIndex(
            (i) => i.item_num === val.item.item_num
          );
          selectedItemsList.splice(removeIndex, 1);
        } else if (
          val.item.status == "NIB" &&
          val.item.fulcrum_id == undefined
        ) {
          const removeIndex = selectedItemsList.findIndex(
            (i) => i.item_num === val.item.item_num
          );
          selectedItemsList.splice(removeIndex, 1);
        }
      };

      const customFilter = (item, queryText, itemText) => {
        const hasValue = (val) => val != null && val !== "";
        const text = hasValue(queryText) ? queryText : "";
        const content = hasValue(itemText) ? itemText : "";

        const normalizedText = content.toLowerCase();
        const normalizedQuery = text.toLowerCase();

        return normalizedText.indexOf(normalizedQuery) > -1;
      };

      watchDebounced(
        search,
        (value) => {
          //if (estimateItems.value.length > 0) return;
          isLoading.value = true;
          //estimateItems.filter(item => item.item_num.includes(value) || item.item_desc.includes(value));
          store.dispatch("estimate-items/filterText", value);
          isLoading.value = false;
        },
        { debounce: 500 }
      );

      watch(
        selectedItemsList,
        (value) => {
          store.dispatch("estimate-items/updateSelectedItemsList", value);
        },
        { deep: true }
      );

      watch(
        fulcrumRemovalItemsList,
        (value) => {
          store.dispatch("estimate-items/updateFulcrumRemovalItemsList", value);
        },
        { deep: true }
      );

      return {
        loading,
        formatCurrency,
        form,
        shouldDialogBeOpen,
        selectedItem,
        updateItemForm,
        addToSelectedItemsList,
        setSelectedItem,
        clearSelectedEstimateItem,
        removeSelectedItem,
        isEditSession,
        selectedEstimateItems,
        selectedItemsList,
        icons: {
          mdiMagnify,
          mdiCurrencyUsd,
          mdiOpenInNew,
          mdiTrashCanOutline,
          mdiFileDocumentEditOutline,
          mdiHandshakeOutline
        },
        search,
        isLoading,
        estimateItems,
        estimateItemsHaveLoaded,
        latestEstimateItemsQuery
      };
    }
  });
</script>

<template>
  <validation-observer ref="form" v-slot="{ invalid, reset }">
    <!-- Add permit item -->
    <div class="add-products-form pt-9 pb-10 px-8">
      <div class="single-product-form">
        <div class="add-products-header mb-2 d-none d-md-flex">
          <div class="px-5 flex-grow-1 font-weight-semibold">
            <v-row dense>
              <v-col cols="12" md="4">
                <span>Item</span>
              </v-col>
              <v-col cols="12" md="2">
                <span>Unit</span>
              </v-col>
              <v-col cols="12" md="2">
                <span>UOM</span>
              </v-col>
              <v-col cols="12" md="2">
                <span>QTY</span>
              </v-col>

              <v-col cols="12" md="2">
                <span>Total</span>
              </v-col>
            </v-row>
          </div>
          <div class="header-spacer"></div>
        </div>
        <v-card class="d-flex" flat>
          <form @submit.prevent="addToSelectedItemsList" @reset.prevent="reset">
            <!-- Left Form -->
            <div class="pa-5 flex-grow-1">
              <v-row dense>
                <v-col cols="12" md="4">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Estimate Item"
                    rules="required"
                    mode="eager"
                  >
                    <v-combobox
                      id="estimate-items"
                      v-if="estimateItemsHaveLoaded"
                      v-model="selectedItem"
                      :items="latestEstimateItemsQuery.response.data"
                      :search-input.sync="search"
                      :loading="isLoading"
                      return-object
                      dense
                      hide-selected
                      clearable
                      no-filter
                      item-value="fulcrum_id"
                      :menu-props="{ offsetY: true }"
                      outlined
                      :placeholder="
                        selectedItem.status == 'NIB' ? 'NIBXXXX' : ''
                      "
                      :error-messages="errors"
                      :prepend-inner-icon="icons.mdiMagnify"
                      @input="(obj) => updateItemForm(obj, 'default')"
                      @click:clear="clearSelectedEstimateItem"
                      tabindex="0"
                    >
                      <template v-slot:selection="{ item }">
                        {{ item.item_num }}
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-content class="font-weight-regular my-5">
                          <v-list-item
                            v-text="`${item.item_num}-${item.item_desc}`"
                          ></v-list-item>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append-item>
                        <v-list-item-content
                          v-if="selectedItem.status == 'NIB'"
                        >
                          <v-list-item>
                            <validation-provider
                              v-slot="{ errors }"
                              name="NIB Item"
                              vid="nib"
                              :rules="{ regexNIB: /(NIB\d{4,})/ }"
                              mode="eager"
                            >
                              <v-text-field
                                id="nib-item"
                                v-model="selectedItem.item_num"
                                filled
                                flat
                                dense
                                clearable
                                hide-details="auto"
                                :placeholder="'NIBXXXX'"
                                :error-messages="errors"
                                @click:clear="clearSelectedEstimateItem"
                                tabindex="1"
                              ></v-text-field>
                            </validation-provider>
                          </v-list-item>
                        </v-list-item-content>
                        <v-list-item-content
                          v-if="selectedItem.status !== 'NIB'"
                        >
                          <v-list-item
                            class="d-flex justify-center font-weight-semibold primary--text"
                            @click="updateItemForm(null, 'custom')"
                          >
                            CREATE NEW ITEM
                          </v-list-item>
                        </v-list-item-content>
                      </template>
                    </v-combobox>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2" sm="4">
                  <!-- Item Unit Cost: Read Only -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Unit Cost"
                    rules="required|double"
                    mode="eager"
                  >
                    <v-text-field
                      id="unit-cost"
                      v-model="selectedItem.item_cost"
                      outlined
                      dense
                      :disabled="selectedItem.negotiable !== 'Yes'"
                      hide-details="auto"
                      :append-icon="
                        selectedItem.negotiable == 'Yes'
                          ? icons.mdiHandshakeOutline
                          : null
                      "
                      :prepend-inner-icon="icons.mdiCurrencyUsd"
                      :error-messages="errors"
                      tabindex="2"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <!-- Item Unit of Measure: Read Only -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Unit of Measure"
                    :rules="{ required: true, regexUppercase: /^[A-Z]+$/ }"
                    mode="eager"
                  >
                    <v-text-field
                      id="unit-of-measure"
                      v-model="selectedItem.item_unit"
                      outlined
                      dense
                      :disabled="
                        selectedItem.negotiable !== 'Yes' ||
                        selectedItem.status !== 'NIB'
                      "
                      hide-details="auto"
                      :error-messages="errors"
                      tabindex="3"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <!-- Item Quantity -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Quantity"
                    rules="required|double:2|min:0.01"
                    mode="eager"
                  >
                    <v-text-field
                      id="qty"
                      v-model.number="selectedItem.item_qty"
                      outlined
                      dense
                      min="0.01"
                      type="number"
                      hide-details="auto"
                      placeholder="QTY"
                      :error-messages="errors"
                      @input="$emit('update', $event)"
                      tabindex="4"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" md="2" sm="4">
                  <!-- Item Total Cost: Read Only -->
                  <p class="font-weight-semibold my-2">
                    <span class="d-inline d-md-none">Price: </span>
                    <span v-if="selectedItem && selectedItem.item_qty > 0">
                      {{
                        formatCurrency(
                          selectedItem.item_cost * selectedItem.item_qty
                        )
                      }}
                    </span>
                    <span v-else>$0.00</span>
                  </p>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="6">
                  <!-- Item Description: Read Only -->
                  <validation-provider
                    v-slot="{ errors }"
                    name="Description"
                    rules="required"
                    mode="eager"
                  >
                    <v-textarea
                      id="Description"
                      v-model="selectedItem.item_desc"
                      outlined
                      :disabled="
                        selectedItem.negotiable !== 'Yes' ||
                        selectedItem.status !== 'NIB'
                      "
                      rows="4"
                      hide-details="auto"
                      placeholder="Description"
                      :error-messages="errors"
                      tabindex="5"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
            </div>
          </form>
        </v-card>
      </div>
      <div class="pa-5">
        <v-row dense>
          <v-col cols="12" md="2">
            <v-btn
              v-if="isEditSession"
              color="primary"
              outlined
              @click="clearSelectedEstimateItem"
            >
              Update Item
            </v-btn>
            <v-btn
              v-else
              color="primary"
              :disabled="invalid"
              @click="addToSelectedItemsList"
            >
              Add Item
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- Display Selected Items -->
    <construction-record-selected-items
      :selectedItems="selectedItemsList"
      :contingency="contingency"
      :datatableKey="datatableKey"
      @set-selected-item="setSelectedItem"
      @remove-selected-item="removeSelectedItem"
    />

    <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </validation-observer>
</template>

<style lang="scss" scoped>
  @import "~@/preset/preset/apps/invoice.scss";

  .app-invoice-editable {
    .input-salesperson {
      width: 100px;
    }

    .select-invoice-to {
      width: 190px !important;
    }

    .header-inputs {
      width: 122px;
    }

    .add-products-form {
      .single-product-form {
        &:not(:first-child) {
          margin-top: 2rem;
        }
      }

      .header-spacer {
        // This is according to item actions width
        width: 0px;
      }

      .item-actions {
        @at-root {
          @include theme--child(add-products-form) using ($material) {
            .item-actions {
              border-left: thin solid map-deep-get($material, "dividers");
            }
          }
        }
      }
    }
  }
</style>
